.info-item-grid-row {
  .info-item-grid-col {
    padding-bottom: 4rem;

    @media (max-width: $LG_BREAKPOINT) {
      padding-bottom: 2rem;
    }

    .info-item-grid-image-col {
      display: flex;
      justify-content: center;
      align-items: flex-start;

      img {
        width: 100%;
        max-width: 250px;
        height: auto;

        @media (max-width: $LG_BREAKPOINT) {
          width: auto;
          max-width: 250px;
          height: 150px;
        }

        @media (max-width: $MD_BREAKPOINT) {
          width: 100%;
          max-width: 250px;
          height: auto;
        }
      }
    }

    .info-item-grid-description-col {
      padding: 1rem 0;

      @media (max-width: $LG_BREAKPOINT) {
        padding: 2rem 0;
      }

      .info-item-grid-title,
      .info-item-grid-description,
      .info-item-grid-link {
        text-align: left;

        @media (max-width: $LG_BREAKPOINT) {
          text-align: center;
        }
      }

      .info-item-grid-description {
        margin: 1rem 0 1.5rem 0;
      }
    }
  }
}
