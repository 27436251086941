@import '~prismjs/themes/prism-solarizedlight.css';
@import '~prismjs/plugins/line-numbers/prism-line-numbers.css';

code .block {
  position: relative;
}

code i.caret {
  position: absolute;
  font-style: normal;
  cursor: pointer;

  width: 10px;
  height: 10px;
  top: -3px;
  left: -12px;
  color: #ccc;
}

code i.caret:before {
  content: '\25B8';
}

code .block-wrapper {
  display: inline-block;
  position: relative;
  overflow: hidden;
  vertical-align: top;

  max-height: 18px;
  max-width: 45px;
  color: #ccc;
}

code .block-wrapper > * {
  opacity: 0;
}

code .block-wrapper:before {
  content: '...';
  top: -2px;
  position: absolute;
  left: 3px;
}

code .block-wrapper:after {
  top: 0px;
  position: absolute;
  right: 0;
}

code .block-keyed-object > .block-wrapper:after,
code .block-object > .block-wrapper:after {
  content: '}';
}

code .block-keyed-array > .block-wrapper:after,
code .block-array > .block-wrapper:after {
  content: ']';
}

code .block-object {
  white-space: pre-wrap;
}

code .block.open > .block-wrapper {
  display: initial;
}

code .block.open > .block-wrapper > * {
  opacity: 1;
}

code .block.open > .block-wrapper:before,
code .block.open > .block-wrapper:after {
  display: none;
}

code .block.open > i.caret:before {
  transform: rotate(90deg);
}

pre[class*='language-'] {
  background: $GRAY_FOUR;
}
