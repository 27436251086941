@font-face {
  font-family: 'Gilroy Medium';
  src:
    url('/assets/common/fonts/gilroy/gilroy-medium.woff2') format('woff2'),
    url('/assets/common/fonts/gilroy/gilroy-medium.woff') format('woff');
}

@font-face {
  font-family: 'Gilroy Semibold';
  src:
    url('/assets/common/fonts/gilroy/gilroy-semi-bold.woff2') format('woff2'),
    url('/assets/common/fonts/gilroy/gilroy-semi-bold.woff') format('woff');
}
