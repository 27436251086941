.mint-form-field-help {
  color: $RED;
  background-color: transparent !important;
  border: 0px solid $RED !important;
  padding: 0 !important;
  margin: 0 !important;

  p {
    font-size: 0.8rem !important;
  }

  &.has-lateral {
    width: 100% !important;
    margin-left: 0 !important;
  }
}

.mint-form-field-invalid {
  border: 1px solid $RED !important;
  box-shadow: none !important;
  outline: none !important;

  &.ant-input:focus {
    border: 1px solid $RED !important;
    box-shadow: none !important;
    outline: none !important;
  }

  .ant-input,
  .ant-input-focused,
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(
      .ant-select-customize-input
    )
    .ant-select-selector,
  .ant-select-selector {
    border: none !important;
    box-shadow: none !important;
    outline: none !important;

    &:hover,
    &:focus,
    &:active {
      border: none !important;
      box-shadow: none !important;
      outline: none !important;
    }
  }
}

.mint-form-field-description {
  color: $TEXT_COLOR_LIGHT;

  p {
    font-size: 0.8rem;
  }
}

mint-plain-text {
  .align-characters-right {
    color: $TEXT_COLOR_LIGHT;
  }
}

// Hide country code input
// mint-input-phone {
//   .ant-input-group-addon {
//     display: none !important;
//   }
// }

mint-input-phone {
  .ant-input-group .ant-select {
    width: 90px !important;
    font-size: 14px;
  }
}

.mint-input-layout {
  label {
    font-size: 0.9rem;
    margin-top: auto;
    margin-bottom: auto;

    sup {
      color: $RED;
    }
  }
}
