.infographic {
  &.odd {
    .infograph-row {
      &:nth-child(odd) {
        flex-direction: row-reverse;

        .infograph-image {
          display: flex;
          justify-content: flex-end;
        }

        @media (max-width: $LG_BREAKPOINT) {
          flex-direction: row;
        }
      }
    }
  }

  &.even {
    .infograph-row {
      &:nth-child(even) {
        flex-direction: row-reverse;

        .infograph-image {
          display: flex;
          justify-content: flex-end;
        }

        @media (max-width: $LG_BREAKPOINT) {
          flex-direction: row;
        }
      }
    }
  }

  .infograph-row {
    padding: 4rem 0;

    .infograph-excerpt {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .infograph-col {
      padding: 1rem 0;

      &.infograph-info {
        padding: 2rem 0;
      }

      .infograph-console {
        padding: 15px 0;
        position: absolute;

        pre,
        code {
          background: $BLACK;
        }

        background: $BLACK;
        border-radius: 15px;

        .line-numbers-rows {
          border-right: none;
        }

        code[class*='language-'] {
          padding-top: 4px;
          overflow: hidden;
        }
      }

      &.infograph-info {
        display: flex;
        flex-direction: column;
        justify-content: center;

        .infograph-title,
        .infograph-description,
        .infograph-link {
          text-align: left;

          @media (max-width: $LG_BREAKPOINT) {
            text-align: center;
          }
        }

        .infograph-description {
          margin: 1rem 0 1.5rem 0;

          p {
            margin: 1.5rem 0 0 0;
            padding: 0;
            font: inherit;
          }
        }
      }

      @media (max-width: $LG_BREAKPOINT) {
        text-align: center;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }
    }
  }
}
