.sub-nav-container {
  position: relative;
  z-index: 1;
  border-radius: 5px;
  background-color: $WHITE;
  box-shadow: 0px 4px 4px transparentize($BLACK, 0.75);
  border: 1px solid $GRAY_SIX;
  border-radius: 10px;

  .sub-nav-menu {
    background: $LIGHT_BLUE;
    padding: 1rem 2rem;
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;

    font-size: 14px;

    hr {
      display: none;
    }
  }

  .sub-nav-content {
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    padding: 1rem 2rem;
  }
}
