.shadow-box {
  position: relative;
  z-index: 1;
  border-radius: 5px;
  background-color: $WHITE;
  box-shadow: 4px 4px 15px transparentize($BLACK, 0.75);

  &.light-shadow {
    box-shadow: 0px 4px 4px transparentize($BLACK, 0.75);
    border: 1px solid $GRAY_SIX;
  }
}

.card {
  border: 0.4px solid transparentize($BLACK, 0.75);
  border-radius: 5px;

  .card-title {
    padding: 1rem 1.5rem;
    border-bottom: 1px solid transparentize($BLACK, 0.75);
    display: flex;
    justify-content: space-between;
    align-items: center;

    h4 {
      margin: 0;
      padding: 0;
    }
  }

  .card-body {
    padding: 1.5rem 1.5rem 0.5rem 1.5rem;
  }
}

.dots {
  font-size: 4px;
  text-align: center;
  margin: 2rem 0;

  span {
    margin: 0 2rem;
  }
}

.circle {
  border-radius: 50%;
  height: 100px;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.dot {
  margin-right: 8px;
  height: 6px;
  width: 6px;
  border-radius: 50%;
  display: inline-block;
}

.status-code-wrap {
  border-radius: 15px;
  color: $WHITE;
  font-weight: bold;
  padding: 1px 15px 0px 15px;
  display: inline-block;
}

.tooltip-template {
  strong {
    font-size: 12px;
    font-family: $FONT_SECONDARY;
  }

  li,
  div {
    margin-top: 5px;
    font-size: 12px;
    list-style: none;
    font-family: $FONT_PRIMARY;
  }
}

.verification-code {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 30px;

  // Hide input increment/decrement arrows
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type='number'] {
    -moz-appearance: textfield;
  }

  input {
    width: 45px !important;
    height: 45px;
    border: none;
    border-bottom: 1px solid $BLACK;
    box-sizing: border-box;
    font-size: 14px;
    line-height: 20px;
    color: $TEXT_COLOR;
    text-align: center;
    margin-right: 8px;
    outline: none;
    box-shadow: none;
    transition: 0.2s;

    &:focus,
    &:hover {
      transition: 0.2s;
      border-top: none !important;
      border-left: none !important;
      border-right: none !important;
      border-bottom: 1px solid $PRIMARY_COLOR_VARIANT !important;
      border-radius: 0 !important;
      box-shadow: none !important;
    }
  }
}

.search-filter-field {
  ::placeholder {
    font-size: 16px;
    color: $TEXT_COLOR_LIGHT;
  }

  input {
    max-width: 100%;
    min-width: 100%;
    border: 1px solid transparentize($BLACK, 0.75);
    border-radius: 5px;
    height: 45px;
    padding: 20px 0px 20px 60px;
    font-weight: 300;
    font-size: 16px;
    line-height: 17px;
    color: $TEXT_COLOR_MUTED;
    background: transparent;
    background-repeat: no-repeat;
    background-position: center left;
    background-position-x: 1em;
    background-size: 1.5em;
    background-image: url('^assets/common/imgs/icons/search-icon.svg');

    @media only screen and (max-width: 550px) {
      min-width: 100%;
    }

    &:hover {
      @include border-boxshadow($SECONDARY_COLOR);
      transition: 0.5s;
    }

    &:focus {
      @include border-boxshadow-strong($SECONDARY_COLOR);
      transition: 0.5s;
      outline: none;
    }
  }
}

.select-filter-fields {
  display: flex;
  align-items: center;

  @media (max-width: $LG_BREAKPOINT) {
    justify-content: start;
  }

  @media (max-width: $MD_BREAKPOINT) {
    margin-bottom: 2rem;
  }

  nz-select {
    margin-right: 7px;
  }

  .ant-select-selection-placeholder {
    color: $TEXT_COLOR_DARK;
  }

  .ant-select,
  .ant-select-selector,
  .ant-select-selector:hover,
  .ant-select-focused:not(.ant-select-disabled).ant-select:not(
      .ant-select-customize-input
    )
    .ant-select-selector {
    max-width: 150px !important;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    width: max-content !important;
    padding: 0 5px 0 0 !important;
  }
}

.table-total-wrap {
  border-radius: 15px;
  color: $TERTIARY_COLOR;
  border: 1px solid $TERTIARY_COLOR;
  padding: 0px 10px;
  display: inline-block;

  .table-total-label {
    @media (max-width: 1300px) {
      display: none;
    }

    @media (max-width: $LG_BREAKPOINT) {
      display: inline-block;
    }
  }
}
