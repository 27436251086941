$WHITE: #ffffff;
$BLACK: #000000;
$OFF_BLACK: #121212;
$OFF_BLACK_TWO: #1d1d1d;
$OFF_BLACK_LIGHT: #282828;

$PRIMARY_COLOR_OLD: #cc5500;
$PRIMARY_COLOR_VARIANT_OLD: #e5804c;
$SECONDARY_COLOR_OLD: #006c82;
$TERTIARY_COLOR_OLD: #4a90e2;

$PRIMARY_COLOR: #cc5500; /* Burnt Orange */
$PRIMARY_COLOR_VARIANT: #cc5500; /* Soft Peach/Terra Cotta */
//$PRIMARY_COLOR_VARIANT: #e5804c; /* Soft Peach/Terra Cotta */
$SECONDARY_COLOR: #367588; /* Teal Blue */
$TERTIARY_COLOR: #7fb3d5; /* Sky Blue */

$GRAY_ONE: #e6e1e5;
$GRAY_TWO: #faf8f9;
$GRAY_THREE: #f2f2f2;
$GRAY_FOUR: #fafafc;
$GRAY_FIVE: #c4c4c4;
$GRAY_SIX: #f0f0f0;

$TEXT_COLOR: #252525;
$TEXT_COLOR_DARK: #333333;
$TEXT_COLOR_MUTED: #5e6366;
$TEXT_COLOR_LIGHT: #999999;
$TEXT_DISABLED: transparentize($BLACK, 0.75);

$YELLOW: #fadb14;
$ORANGE: #fe7e0e;
$RED: #f54b52;
$GREEN: #21af63;
$GREEN_TWO: #00b192;
$GREEN_THREE: #54aa7a;
$LIGHT_BROWN: rgba(255, 247, 230, 0.5);
$LIGHT_BLUE: #e6f1f3;

$FONT_LOGO: 'Century Gothic Light', sans-serif;
$FONT_PRIMARY: 'Inter Regular', sans-serif;
$FONT_SECONDARY: 'Gilroy Medium', Arial, sans-serif;
$FONT_SECONDARY_SEMIBOLD: 'Gilroy Semibold', Arial, sans-serif;
$FONT_SIZE: 16px;

$XL_BREAKPOINT: 1200px;
$LG_BREAKPOINT: 992px;
$MD_BREAKPOINT: 768px;
$SM_BREAKPOINT: 576px;

$HEADER_HEIGHT: 90px;
$HEADER_SCROLLED_HEIGHT: 70px;
$SIDEBAR_NAV_WIDTH: 240px;
