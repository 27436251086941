// Layout
html {
  --antd-wave-shadow-color: $PRIMARY_COLOR_VARIANT !important;
}

.ant-layout-footer {
  color: $TEXT_COLOR;
  padding: 0;
}

.ant-layout {
  background: transparent;
}

.ant-layout-header {
  color: $TEXT_COLOR;
  padding: 0;
  height: max-content;
  line-height: unset;
  background: transparent;
}

// Menus
.ant-menu-vertical,
.ant-menu-horizontal {
  border: none;
}

.ant-menu,
.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected,
.ant-menu-item:active,
.ant-menu-submenu-title:active {
  background: transparent !important;
}

.ant-menu-item,
.ant-menu-submenu {
  &:hover,
  &:not(:hover) {
    &::after {
      border: none !important;
    }
  }
}

.ant-menu-submenu {
  .ant-menu-item {
    border-bottom: none !important;
  }
}

.ant-menu-submenu-arrow {
  display: none;
}

.ant-menu-submenu-selected > span,
.ant-menu-submenu-selected i,
.ant-menu-item-selected a,
.ant-menu-submenu-selected .ant-menu-submenu-title:after {
  color: $PRIMARY_COLOR_VARIANT !important;
}

.ant-menu-sub {
  padding: 0.5rem 0 !important;
}

.ant-menu-submenu-popup {
  .ant-menu-item-group-title {
    display: none;
  }

  background-color: $WHITE;
  position: absolute !important;
  z-index: 10 !important;
  padding-top: 0 !important;

  a {
    &:hover,
    &.active {
      color: $PRIMARY_COLOR_VARIANT;
      text-decoration: none;
    }
  }
}

// Toggle Switch
.ant-switch {
  &:focus,
  &:active {
    box-shadow: 0 0 0 2px transparentize($GREEN_TWO, 0.7);
  }
}

.ant-switch-checked {
  background-color: transparentize($GREEN_TWO, 0.3);
}

// Accordion
.ant-collapse-borderless {
  box-shadow: 2px 2px 8px transparentize($BLACK, 0.9);

  .ant-collapse-header {
    height: max-content;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-size: 22px;
    font-weight: 500;
    margin-left: 0.25rem !important;
    padding-right: 50px !important;
    color: $TEXT_COLOR_DARK !important;
  }

  svg {
    transition: 0.2s;
  }

  .ant-collapse-item-active {
    .custom-collapse-expand-icon {
      svg {
        transform: rotate(180deg) !important;
        transition: 0.2s;
      }
    }
  }

  .ant-collapse-content-box {
    margin-left: 4px;
    font-size: 18px;
    text-align: left;
    color: $TEXT_COLOR;
  }
}

// Breadcrumbs
.ant-breadcrumb {
  a {
    @include actionable();
    color: $TERTIARY_COLOR;

    &:hover {
      color: lighten($TERTIARY_COLOR, 10%);
      text-decoration: underline;
    }
  }

  .ant-breadcrumb-separator {
    color: $TEXT_COLOR;
  }

  nz-breadcrumb-item:last-child a {
    color: $TEXT_COLOR;
    cursor: initial;
    &:hover {
      text-decoration: none;
    }
  }
}

// Tooltips
.ant-tooltip-inner {
  font-family: $FONT_PRIMARY !important;
  padding: 17px 15px 15px 15px;
  color: $TEXT_COLOR_DARK;
  background-color: $WHITE;
  font-size: 11px;
  // box-shadow: 0 -5px 20px 2px transparentize($BLACK, 0.9);
}

.ant-tooltip-arrow-content {
  --antd-arrow-background-color: $WHITE !important;
  background-color: $WHITE !important;
}

// Popover {
.ant-popover-arrow {
  display: none;
}

// Alerts
.ant-alert {
  background: $WHITE;
  border-radius: 5px;

  .ant-alert-message {
    color: $TEXT_COLOR_DARK;
    font-family: $FONT_SECONDARY;
    font-size: 14px;
    font-weight: bold;
  }

  .ant-alert-description {
    margin-top: 5px;
    font-size: 16px;
  }

  .anticon {
    font-size: 18px;
    margin-right: 10px;
  }

  &.ant-alert-error {
    border: 2px solid $RED;

    .anticon {
      color: $RED;
    }
  }

  &.ant-alert-info {
    border: 2px solid $TERTIARY_COLOR;

    .anticon {
      color: $TERTIARY_COLOR;
    }
  }

  &.ant-alert-warning {
    border: 2px solid $ORANGE;

    .anticon {
      color: $ORANGE;
    }
  }

  &.ant-alert-success {
    border: 2px solid $GREEN_TWO;

    .anticon {
      color: $GREEN_TWO;
    }
  }
}

// Modal
.ant-modal {
  .ant-modal-close {
    .anticon {
      color: $TERTIARY_COLOR;
    }
  }
}

.ant-modal-mask {
  background: transparentize($TEXT_COLOR_MUTED, 0.6);
}

// Text Inputs
.ant-picker {
  svg {
    fill: $TERTIARY_COLOR;
  }
}

.ant-picker,
.ant-input,
.ant-input-affix-wrapper:hover {
  &:hover {
    @include border-boxshadow($SECONDARY_COLOR);
  }
}

.ant-picker-focused,
.ant-input-affix-wrapper-focused,
.ant-input-affix-wrapper:focus,
.ant-input-focused,
.ant-input:focus {
  &:not(.ant-input-group-wrapper) {
    @include border-boxshadow-strong($SECONDARY_COLOR);
  }
}

.ant-input {
  min-height: 40px;

  &::placeholder {
    font-size: 0.9rem;
  }
}

.ant-input-affix-wrapper {
  padding: 0 !important;

  .ant-input {
    padding: 4px 11px !important;
    border: none !important;
    box-shadow: none !important;
    &:hover,
    &:active,
    &:focus {
      border: none !important;
      box-shadow: none !important;
    }
  }

  .ant-input-suffix {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
  }

  .password-visiblity-toggle-icon {
    color: $TERTIARY_COLOR;
  }
}

// Input Select
.ant-select,
.ant-select-single {
  position: relative;
  top: 1px;
  width: 100%;

  &:not(.ant-select-customize-input) {
    .ant-select-selector {
      //padding: 4px 11px !important;
      //height: auto !important;
      height: 41px !important;
    }
  }

  nz-select-item,
  nz-select-search {
    //padding-top: 5px !important;
  }

  .ant-select-selection-placeholder {
    font-size: 0.9rem !important;
  }

  .ant-select-selection-item {
    div,
    span {
      display: flex;
      align-items: center;
    }
  }
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background: transparentize($PRIMARY_COLOR_VARIANT, 0.8) !important;
  font-weight: normal;
}

.ant-select-selected-icon {
  display: none;
}

.ant-select-selector {
  &:hover {
    border: solid 1px transparentize($SECONDARY_COLOR, 0.35) !important;
    box-shadow: 0 0 0 2px transparentize($SECONDARY_COLOR, 0.85) !important;
  }
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  @include border-boxshadow-strong($SECONDARY_COLOR);
}

.ant-select-focused {
  border-radius: 5px !important;
}

.cdk-virtual-scroll-spacer {
  display: none;
}

.ant-select-selection-item-content {
  font-size: 0.8rem;
}

// Radio Buttons
.ant-radio-checked {
  .ant-radio-inner {
    border-color: $PRIMARY_COLOR_VARIANT !important ;
  }

  .ant-radio-inner:after {
    background-color: $PRIMARY_COLOR_VARIANT;
  }
}

.ant-radio {
  &:hover {
    .ant-radio-inner {
      border-color: $PRIMARY_COLOR_VARIANT;
    }
  }
}

// Checkboxes
.ant-checkbox-checked {
  .ant-checkbox-inner {
    background-color: $PRIMARY_COLOR_VARIANT;
    border-color: $PRIMARY_COLOR_VARIANT;
  }
}

// Tags
.ant-tag {
  margin-top: 5px;
}

.ant-tag,
.ant-select-multiple .ant-select-selection-item {
  padding: 5px 10px;
  margin-right: 5px;
  border-radius: 15px;
  background: $PRIMARY_COLOR_VARIANT;
  color: $WHITE;

  .anticon {
    color: $WHITE !important;
  }
}

.nz-select-tags-dropdown {
  display: none;
}

// Tables
.ant-pagination {
  display: flex;
  align-items: center;
  justify-content: center;

  .ant-pagination-next,
  .ant-pagination-prev {
    cursor: pointer;
    svg {
      path {
        fill: $SECONDARY_COLOR;
        transition: 0.2s;
      }
    }
  }

  .ant-pagination-disabled {
    svg {
      cursor: not-allowed !important;

      path {
        fill: $GRAY_ONE !important;
      }
    }
  }

  .ant-pagination-item {
    border: none;

    div {
      margin: 0.6rem 1rem;

      border-radius: 50%;
      border: none;
      transition: 0.2s;

      width: 30px;
      height: 30px;

      a {
        color: $TEXT_COLOR;
        transition: 0.2s;
      }
    }

    &:hover {
      div {
        background-color: $SECONDARY_COLOR;
        color: $WHITE;
        transition: 0.2s;

        a {
          color: $WHITE;
          transition: 0.2s;
        }
      }
    }
  }

  .ant-pagination-item-active {
    div {
      background-color: $SECONDARY_COLOR;
      a {
        color: $WHITE;
      }
    }
  }
}

// Skeleton
.ant-skeleton-input {
  width: 100% !important;
  height: 100% !important;
  border-radius: 5px;
}

// Loading
.ant-spin-nested-loading {
  position: unset;
}

.ant-spin-nested-loading > div > .ant-spin {
  max-height: 100%;
}
