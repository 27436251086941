.accordion-container {
  .ant-collapse-borderless {
    box-shadow: none;
    background: $WHITE;
    border-bottom: 1px solid $GRAY_ONE;

    .ant-collapse-header {
      font-size: 16px !important;
      font-weight: bold;
      margin-left: 0 !important;
      padding: 16px 16px 16px 50px;
      font-family: $FONT_SECONDARY;
    }

    .custom-collapse-expand-icon-left {
      svg {
        height: 12px;
        width: 15px;

        transform: rotate(-90deg) !important;

        path {
          fill: transparentize($BLACK, 0.75) !important;
        }
      }
    }

    .ant-collapse-item-active {
      .custom-collapse-expand-icon-left {
        svg {
          transform: rotate(0deg) !important;
        }
      }
    }

    .ant-collapse-content-box {
      margin-left: 35px;
      font-size: 16px;
    }
  }
}
