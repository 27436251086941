* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html,
body {
  min-height: 100vh;
  top: 0 !important; // fixes issue with ant modals moving <html> tag

  font-size: $FONT_SIZE;
}

body {
  font-family: $FONT_PRIMARY;
  font-weight: 400;
  color: $TEXT_COLOR;
  overflow-x: hidden;

  @media (max-width: $LG_BREAKPOINT) {
    font-size: 90%;
  }

  @media (max-width: $MD_BREAKPOINT) {
    font-size: 85%;
  }

  @media (max-width: $SM_BREAKPOINT) {
    font-size: 80%;
  }
}

h1 {
  font-family: $FONT_SECONDARY_SEMIBOLD;
  color: $SECONDARY_COLOR;
  font-size: 30px;
  font-weight: 500;
  line-height: 50px;
}

h2 {
  font-family: $FONT_SECONDARY;
  color: $OFF_BLACK;
  font-size: 22px;
  font-weight: 500;
  line-height: 28px;

  &.light {
    color: $WHITE;
  }
}

h3 {
  font-family: $FONT_SECONDARY;
  color: $OFF_BLACK;
  font-size: 28px;
  font-weight: 500;
  line-height: 28px;

  &.light {
    color: $WHITE;
  }
}

h4 {
  font-family: $FONT_SECONDARY_SEMIBOLD;
  color: $SECONDARY_COLOR;
  font-size: 18px;
  font-weight: 500;
  line-height: 20px;

  &.underline {
    border-bottom: 1px solid $BLACK;
  }
}
ul {
  margin: 0;
}

p {
  line-height: 34px;
  font-size: $FONT_SIZE;
  font-size: 18px;

  &.sm {
    font-size: 16px;
  }
}

a {
  @include actionable();
  color: $TERTIARY_COLOR;

  &:disabled,
  &.disabled {
    color: $TEXT_DISABLED !important;
    cursor: not-allowed;

    &:hover {
      text-decoration: none;
    }
  }

  &:hover {
    text-decoration: underline;
  }
}

textarea {
  resize: none;
}

pre {
  background-color: $GRAY_FOUR;
  border-radius: 5px;
  padding: 20px;
}

pre,
code {
  white-space: pre-wrap !important;
  word-break: break-all !important;
}

::selection {
  background: $TERTIARY_COLOR;
}
