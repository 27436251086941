.content-section {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  padding: 2rem;

  &.gray-bg {
    background-color: $GRAY_TWO;
  }

  &.off-black-bg {
    background-color: $OFF_BLACK;
  }

  .content-row {
    margin: 0 auto;
    // display: flex;

    @media (max-width: $XL_BREAKPOINT) {
      width: 100%;
    }

    h3 {
      color: $SECONDARY_COLOR;
      margin: 1.5rem 0;
    }

    .content-title {
      text-align: center;

      h4 {
        font-size: 2rem;
        font-weight: bold;
        color: $PRIMARY_COLOR;
        font-family: $FONT_SECONDARY;

        @media (max-width: $MD_BREAKPOINT) {
          font-size: 1.5rem;
        }
      }
    }

    .content-excerpt {
      font-size: 1.375rem;

      padding-top: 5rem;

      &.dark {
        h1 {
          color: $WHITE !important;
        }

        p {
          color: $WHITE !important;
        }
      }

      h1 {
        font-size: 3rem;
        font-family: $FONT_SECONDARY;
        color: $PRIMARY_COLOR;
        margin-bottom: 2rem;
        font-weight: 500;
        line-height: 1.2;

        @media (max-width: $LG_BREAKPOINT) {
          font-size: 2.5rem;
        }
      }

      p {
        max-width: 400px;
        margin-bottom: 1.6rem;
        line-height: 28px;
        color: $TEXT_COLOR_DARK;

        @media (max-width: $LG_BREAKPOINT) {
          margin: 0 auto 1.6rem auto;
        }
      }

      @media (max-width: $LG_BREAKPOINT) {
        margin: 0 auto 5rem auto;
        max-width: 700px;
        text-align: center;
      }
    }

    .content-img {
      padding-top: 1rem;

      .img {
        height: max-content;
        display: inline-block;
        position: relative;

        img {
          position: relative;
          z-index: 1;
        }
      }

      @media (max-width: $LG_BREAKPOINT) {
        margin: 2rem 0;
        text-align: center;
      }
    }
  }
}
