.form-field {
  display: flex;
  margin-bottom: 2rem;

  &.mb-1 {
    margin-bottom: 1.5rem;
  }

  &.inline {
    flex-direction: column;

    .form-label {
      padding-top: 0px;
    }

    .form-input {
      position: relative;
      max-width: 700px;
      min-width: 700px;

      .ant-picker {
        width: 100%;
      }

      .tags {
        max-width: 700px;
      }

      @media (max-width: 1150px) {
        min-width: 100%;
      }
    }
  }

  @media (max-width: $LG_BREAKPOINT) {
    flex-direction: column;
  }

  .form-label {
    padding-top: 10px;
    padding-bottom: 8px;

    min-width: 250px;
  }

  .form-input {
    position: relative;
    max-width: 500px;
    min-width: 500px;

    @media (max-width: 1150px) {
      min-width: 100%;
    }

    &.tags-input {
      .cdk-overlay-pane {
        display: none;
      }
    }

    .ant-picker {
      width: 100%;
    }

    .select-items-selected {
      position: absolute;
      top: 7px;
      left: 10px;
    }

    .tags {
      max-width: 500px;
    }

    .form-input-description {
      line-height: 1.5;
      color: $TEXT_COLOR_LIGHT;
      font-size: 0.8rem;
    }
  }
}

.form-group-label {
  color: $TEXT_COLOR_MUTED;
  font-size: 14px;

  .required {
    color: $RED;
  }
}

.form-fields-inline {
  display: flex;
  gap: 15px;

  .form-field-inline {
    width: 50%;
  }

  @media (max-width: $LG_BREAKPOINT) {
    flex-direction: column;

    .form-field-inline {
      width: 100%;
    }
  }
}

.form-label-w-icon {
  display: flex;
  align-items: center;

  .label-icon {
    position: relative;
    top: 6px;
    left: 2px;
  }
}
