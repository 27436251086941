.info-card-row {
  min-height: 500px;
  height: max-content;
  padding: 0;

  @media (max-width: $MD_BREAKPOINT) {
    justify-content: center;
  }

  @media (max-width: $LG_BREAKPOINT) {
    margin: 0 auto;
    width: 100%;
  }

  .info-card-col {
    max-width: 500px;
    min-height: 500px;

    padding-right: 2rem;
    padding-bottom: 4rem;

    @media (max-width: $LG_BREAKPOINT) {
      margin-top: 2rem;
    }

    .info-card {
      display: flex;
      justify-content: center;
      text-align: center;
      position: relative;
      width: 100%;
      height: 100%;
      box-shadow: 4px 4px 15px transparentize($BLACK, 0.75);
      background-color: $WHITE;
      border-radius: 5px;

      .info-card-content {
        display: flex;
        flex-direction: column;
        max-width: 400px;
        padding: 5rem 1.5rem 2rem 1.5rem;

        .info-card-icon {
          // height: 130px;
        }

        .info-card-excerpt {
          margin-top: 4rem;
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          align-items: center;
          min-height: calc(100% - 135px);
          padding-top: 1rem;
          font-size: 16px;

          .info-card-title {
            font-family: $FONT_SECONDARY;
            color: $SECONDARY_COLOR;
            font-weight: 600;
            font-size: 16px;
            padding-bottom: 1rem;
          }

          p {
            color: $TEXT_COLOR;
            font-weight: 400;
            margin: 0;

            @media (max-width: $LG_BREAKPOINT) {
              font-size: 1.125rem;
            }
          }

          button {
            margin-top: 2rem;
            padding: 0.5rem 1.5rem;
          }
        }
      }

      .info-card-footer {
        width: 100%;
        padding: 1rem;
        text-align: center;
        font-weight: bold;

        .info-card-tool-title {
          color: $PRIMARY_COLOR;
          font-family: $FONT_SECONDARY;
          display: inline-block;
          text-transform: uppercase;
          font-weight: normal;
        }
      }
    }
  }
}
