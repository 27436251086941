.light-link {
  @include actionable();
  @include color-transition($WHITE, $PRIMARY_COLOR_VARIANT);
}

.dark-link {
  @include actionable();
  @include color-transition($OFF_BLACK, $PRIMARY_COLOR_VARIANT);
}

.light-action-icon {
  @include actionable();
  @include svg-transition($WHITE, $PRIMARY_COLOR_VARIANT);
}

.dark-action-icon {
  @include actionable();
  @include svg-transition($OFF_BLACK, $PRIMARY_COLOR_VARIANT);
}

.primary-color-action-icon {
  @include actionable();
  @include svg-transition($PRIMARY_COLOR, $PRIMARY_COLOR_VARIANT);
}

.secondary-color-action-icon {
  @include actionable();
  @include svg-transition($SECONDARY_COLOR, lighten($SECONDARY_COLOR, 10%));
}

.tertiary-color-action-icon {
  @include actionable();
  @include svg-transition($TERTIARY_COLOR, lighten($TERTIARY_COLOR, 10%));
}

.page-container {
  padding: 2rem !important;
}

.title {
  font-family: $FONT_SECONDARY;
  color: $OFF_BLACK;
  font-size: 48px;
  font-weight: 400;
  line-height: 60px;

  &.light {
    color: $WHITE;
  }

  &.blue {
    color: $SECONDARY_COLOR;
  }

  &.small {
    font-size: 30px;
  }

  @media (max-width: $LG_BREAKPOINT) {
    font-size: 30px;

    &.small {
      font-size: 25px;
    }
  }
}

.subhead {
  font-family: $FONT_SECONDARY;
  color: $TEXT_COLOR_DARK;
  font-size: 1.375rem !important;
  font-weight: 600;
  margin-bottom: 1.5rem !important;
}

.dark-heading {
  font-size: 30px;
  font-weight: 500;
  font-family: $FONT_SECONDARY;
  color: $TEXT_COLOR_DARK;

  @media (max-width: $LG_BREAKPOINT) {
    font-size: 20px;
  }
}

.dark-heading-caption {
  font-size: 22px;
  font-weight: 500;

  &.sm {
    font-size: 18px;
  }

  @media (max-width: $LG_BREAKPOINT) {
    font-size: 16px;

    &.sm {
      font-size: 14px;
    }
  }
}

.flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.position-relative {
  position: relative;
}

.no-pad {
  padding: 0 !important;
}

.no-margin {
  margin: 0 !important;
}

.no-pad-margin {
  margin: 0 !important;
  padding: 0 !important;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-initial {
  cursor: initial;
}

.flex {
  display: flex;
}

.inline-flex {
  display: flex;
}

.flex-col {
  flex-direction: column;
}

.flex-wrap {
  flex-wrap: wrap;
}

.jc-center {
  justify-content: center;
}

.jc-end {
  justify-content: flex-end;
}

.jc-between {
  justify-content: space-between;
}

.ai-start {
  align-items: flex-start;
}

.ai-center {
  align-items: center;
}

.max-w-50 {
  max-width: 50%;
}

.max-w-75 {
  max-width: 75%;
}

.w-100 {
  width: 100%;
}

.text-xsmall {
  font-size: 12px;
}

.text-small {
  font-size: 14px;
}

.text-normal {
  font-size: $FONT_SIZE; // 16px
}

.text-large {
  font-size: 18px;
}

.text-xlarge {
  font-size: 20px;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-bold {
  font-weight: bold;
}

.text-dark {
  color: $TEXT_COLOR_DARK;
}

.text-muted {
  color: $TEXT_COLOR_MUTED;
}

.text-light {
  color: $TEXT_COLOR_LIGHT;
}

.black {
  color: $BLACK;
}

.green {
  color: $GREEN;
}

.green-two {
  color: $GREEN_TWO;
}

.green-three {
  color: $GREEN_THREE;
}

.green-bg {
  background: $GREEN;
}

.green-2-bg {
  background: $GREEN_TWO;
}

.red {
  color: $RED;
}

.red-bg {
  background: $RED;
}

.orange {
  color: $ORANGE;
}

.yellow {
  color: $YELLOW;
}

.secondary-color {
  color: $SECONDARY_COLOR;
}

.secondary-color-bg {
  background: $SECONDARY_COLOR;
}

.tertiary-color {
  color: $TERTIARY_COLOR;
}

.tertiary-color-bg {
  background: $TERTIARY_COLOR;
}

.font-primary {
  font-family: $FONT_PRIMARY !important;
}

.font-secondary {
  font-family: $FONT_SECONDARY !important;
}

.font-secondary-semibold {
  font-family: $FONT_SECONDARY_SEMIBOLD !important;
}

.gray-bottom-border {
  border-bottom: 1px solid $GRAY_ONE;
}

.gray-top-border {
  border-top: 1px solid $GRAY_ONE;
}

.gray-bottom-border-heavy {
  border-bottom: 2px solid $GRAY_ONE;
}

.monospace {
  font-family: monospace;
}

.mr-p5 {
  margin-right: 0.5rem !important;
}

.mr-05 {
  margin-right: 0.5rem !important;
}

.mr-1 {
  margin-right: 1rem !important;
}

.mr-2 {
  margin-right: 2rem !important;
}

.mr-3 {
  margin-right: 3rem !important;
}

.mr-4 {
  margin-right: 4rem !important;
}

.mt-auto {
  margin-top: auto;
}

.mt-p5 {
  margin-top: 0.5rem !important;
}

.mt-1 {
  margin-top: 1rem !important;
}

.mt-2 {
  margin-top: 2rem !important;
}

.mt-3 {
  margin-top: 3rem !important;
}

.mt-4 {
  margin-top: 4rem !important;
}

.mt-5 {
  margin-top: 5rem !important;
}

.mt-9 {
  margin-top: 9rem !important;
}

.mb-p5 {
  margin-bottom: 0.5rem !important;
}

.mb-1 {
  margin-bottom: 1rem !important;
}

.mb-1p5 {
  margin-bottom: 1.5rem !important;
}

.mb-2 {
  margin-bottom: 2rem !important;
}

.mb-3 {
  margin-bottom: 3rem !important;
}

.mb-4 {
  margin-bottom: 4rem !important;
}

.ml-05 {
  margin-left: 0.5rem !important;
}

.ml-1 {
  margin-left: 1rem !important;
}

.ml-2 {
  margin-left: 2rem !important;
}

.my-p5 {
  margin-top: 0.5rem !important;
  margin-bottom: 0.5rem !important;
}

.my-1 {
  margin-top: 1rem !important;
  margin-bottom: 1rem !important;
}

.my-2 {
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.my-3 {
  margin-top: 3rem !important;
  margin-bottom: 3rem !important;
}

.my-4 {
  margin-top: 4rem !important;
  margin-bottom: 4rem !important;
}

.mx-1 {
  margin-left: 1rem !important;
  margin-right: 1rem !important;
}

.mx-2 {
  margin-left: 2rem !important;
  margin-right: 2rem !important;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.pt-1 {
  padding-top: 1rem !important;
}

.pt-2 {
  padding-top: 2rem !important;
}

.pt-3 {
  padding-top: 3rem !important;
}

.pt-4 {
  padding-top: 4rem !important;
}

.pt-5 {
  padding-top: 5rem !important;
}

.pb-1 {
  padding-bottom: 1rem !important;
}

.pb-2 {
  padding-bottom: 2rem !important;
}

.pb-3 {
  padding-bottom: 3rem !important;
}

.pb-4 {
  padding-bottom: 4rem !important;
}

.pb-5 {
  padding-bottom: 5rem !important;
}

.pl-1 {
  padding-left: 1rem !important;
}

.pr-2 {
  padding-right: 2rem !important;
}

.px-1 {
  padding-left: 1rem !important;
  padding-right: 1rem !important;
}

.px-1p5 {
  padding-left: 1.5rem !important;
  padding-right: 1.5rem !important;
}

.px-2 {
  padding-left: 2rem !important;
  padding-right: 2rem !important;
}

.px-3 {
  padding-left: 3rem !important;
  padding-right: 3rem !important;
}

.px-4 {
  padding-left: 4rem !important;
  padding-right: 4rem !important;
}

.py-1 {
  padding-top: 1rem !important;
  padding-bottom: 1rem !important;
}

.py-2 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

.py-3 {
  padding-top: 3rem !important;
  padding-bottom: 3rem !important;
}

.py-4 {
  padding-top: 4rem !important;
  padding-bottom: 4rem !important;
}

.p-1 {
  padding: 1rem !important;
}

.p-1p5 {
  padding: 1.5rem !important;
}

.p-2 {
  padding: 2rem !important;
}

.p-3 {
  padding: 3rem !important;
}

.p-4 {
  padding: 4rem !important;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.svg-fluid {
  width: 100%;
  height: auto;
}

.word-break-all {
  word-break: break-all;
}

.word-wrap-break-word {
  word-wrap: break-word;
}

.responsive-table {
  .td-label {
    font-weight: bold;
    display: none;
  }

  @media (max-width: $LG_BREAKPOINT) {
    th,
    thead {
      display: none;
    }

    table,
    tbody,
    tr,
    td {
      display: block;
      width: 100%;
    }

    .td-label {
      display: block;
      margin-bottom: 10px;
    }
  }

  .td-actions {
    display: flex;
    align-items: center;

    @media (max-width: 1400px) {
      flex-direction: column;
      align-items: flex-start;
      justify-content: center;
    }
  }
}
