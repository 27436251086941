.related-posts-container {
  min-width: 100%;

  .related-posts-header {
    padding: 1rem 0;
    margin: 1rem 0;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $GRAY_ONE;

    .related-posts-header-title {
      color: $SECONDARY_COLOR;
      font-family: $FONT_SECONDARY;
      font-size: 20px;
      font-weight: bold;
    }

    .related-posts-header-link {
      font-size: 18px;
      font-weight: bold;
    }
  }

  .related-post-card-col {
    .related-post-card-container {
      cursor: pointer;
      margin-top: 1rem;
      border: 0;

      @media (max-width: $MD_BREAKPOINT) {
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin-right: 0;
      }

      @media (max-width: $LG_BREAKPOINT) {
        margin-bottom: 1rem;
      }

      a:hover {
        text-decoration: none;
      }

      .related-post-category {
        font-family: $FONT_SECONDARY;
        margin-top: 0.7rem;
        margin-bottom: 0.7rem;
        font-size: 0.875rem;
        color: $SECONDARY_COLOR;

        @media (max-width: $MD_BREAKPOINT) {
          text-align: center;
        }
      }

      .related-post-header {
        font-family: $FONT_SECONDARY;
        margin-top: 0.7rem;
        line-height: 1.5;

        .related-post-title {
          color: $TEXT_COLOR_DARK;
          font-weight: 500;
          font-size: 22px;
        }

        .related-post-subtitle {
          margin-top: 0.3rem;
          color: $TEXT_COLOR_MUTED;
          font-size: 18px;
        }

        @media (max-width: $MD_BREAKPOINT) {
          text-align: center;
        }
      }

      .related-post-footer {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        color: $TEXT_COLOR;
        font-size: 0.875rem;
        margin-top: 1.2rem;

        .related-post-date {
        }

        .related-post-read-time {
        }

        @media (max-width: $MD_BREAKPOINT) {
          text-align: center;
          flex-flow: column;
        }
      }

      .related-post-card-image {
        border-radius: 0;

        @media (max-width: $MD_BREAKPOINT) {
          width: 85%;
        }

        img {
          flex-shrink: 0;
          width: 100%;
          vertical-align: middle;
        }
      }
    }

    .no-results-text {
      padding-top: 3rem;

      @media (max-width: $MD_BREAKPOINT) {
        padding-top: 1.5rem;
      }
    }
  }
}
